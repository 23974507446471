$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/carreisen-ostschweiz/';
@import 'styles/helpers/global_variables';

$font-color: $gray-400;

.root {
  margin-top: 0.5rem;

  .title {
    display: block;
    text-transform: uppercase;
    @include font-bold;
  }
  .subTitle {
    display: block;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  .country {
    display: inline-block;
    width: 30px;
  }
}
