$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/carreisen-ostschweiz/';
@import 'styles/helpers/global_variables';

$accordion-border-color: $gray-400;
$left-spacing: 3rem;

.root {
  .title {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }

  .menu {
    margin-bottom: 1rem;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li > a {
        display: block;
        padding: 0.25rem 0;
        line-height: 1;
      }
    }
  }

  .accordionHeader {
    background: none;
    border: none;
    padding: 0;

    & button {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      background: none;

      &:not(.collapsed) {
        box-shadow: none;
      }
    }
  }

  .accordionItem {
    background: none;
    border-color: $accordion-border-color;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    @at-root .unstyledWhite .accordionItem {
      border: none;
    }
  }

  .accordion + .accordion:last-of-type {
    .accordionItem {
      border-bottom: $accordion-border-width solid $accordion-border-color;
    }
  }

  .accordionButton {
    color: inherit;
    width: 100%;
    padding: 0.7rem;
    padding-left: $left-spacing;
    border: 0;
    text-align: left;

    &:focus-visible {
      outline: 0;
    }

    &::after {
      // chevron down
      content: '';
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 1rem 0.8rem;
      position: absolute;
      left: 0.75rem;
      z-index: 6;
      top: 0.9rem;
      width: 1rem;
      height: 0.8rem;
      transition-duration: 0.2s;
      transition-property: transform;
      transform: rotate(-90deg);

      //@at-root .unstyledWhite .accordionButton::after {
      //  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      //}
    }

    &:hover,
    &.active {
      &::after {
        //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236c757d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      }
    }
    @at-root .unstyledWhite .accordionButton {
      &.active {
        &::after {
          //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        }
      }
      &:hover {
        &::after {
          //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236c757d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        }
      }
    }

    &.active::after {
      transform: rotate(0deg);
    }
  }

  .cardBody {
    padding: 0 0 0.8rem $left-spacing;

    @include media-breakpoint-up(md) {
      padding: 0.5rem;
    }
  }
}
